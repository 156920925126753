import { useEffect, useRef } from 'react'
import uavSchematic from '#app/assets/uav-schematic-hero.png'
import { Badge } from '#app/components/ui/badge.tsx'
import { JoinWaitlist } from './join-waitlist'

export const Hero = () => {
	const sectionRef = useRef<HTMLElement>(null)

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry?.isIntersecting) {
					entry.target.classList.add('opacity-100')
				}
			},
			{
				threshold: 0.1,
			},
		)

		if (sectionRef.current) {
			observer.observe(sectionRef.current)
		}
		const sectionReference = sectionRef.current

		return () => {
			if (sectionReference) {
				observer.unobserve(sectionReference)
			}
		}
	}, [])

	return (
		<section
			ref={sectionRef}
			className="flex min-h-screen items-center justify-center pt-10 opacity-0 transition-opacity duration-700 ease-in-out lg:pt-20"
			style={{
				background: 'linear-gradient(135deg, #F5F7FA 0%, #E4ECF7 100%)',
			}}
		>
			<div className="container mx-auto max-w-7xl px-4 py-16 sm:px-6 md:py-24 lg:px-8">
				<div className="grid grid-cols-1 items-center gap-12 lg:grid-cols-2">
					<div className="order-2 lg:order-1">
						<Badge className="mb-6 inline-flex animate-fade-in items-center rounded-full bg-empirium-teal/10 px-3 py-1 text-sm font-medium text-empirium-teal opacity-0 hover:bg-empirium-teal/10">
							Introducing
						</Badge>

						<h1 className="mb-4 animate-fade-in-delay-1 font-michroma text-4xl font-bold text-empirium-teal opacity-0 sm:text-5xl md:text-7xl lg:text-7xl">
							EMPIRIUM
						</h1>

						<p className="mb-4 animate-fade-in-delay-2 text-xl font-medium text-gray-600 opacity-0 md:text-2xl">
							The global marketplace for critical supply
						</p>

						<p className="mb-8 animate-fade-in-delay-3 text-lg text-gray-600 opacity-0">
							Frustrated by the expensive, time-consuming search for trusted
							buyers and sellers? Empirium streamlines your supply chain with
							powerful tools for verification, trade, and logistics.
						</p>

						<div className="flex animate-fade-in-delay-4 flex-col gap-4 opacity-0 sm:flex-row">
							<JoinWaitlist intent="join-waitlist" />
							<JoinWaitlist intent="request-demo" />
						</div>
					</div>

					<div className="order-2 flex animate-fade-in-delay-2 justify-center opacity-0 lg:order-2">
						<div className="relative w-full max-w-md lg:max-w-lg xl:max-w-xl">
							<div className="absolute inset-0 z-0 scale-105 rounded-xl bg-gradient-to-br from-empirium-dark/10 to-empirium-teal/10 blur-lg"></div>
							<div className="glassmorphism relative z-10 overflow-hidden rounded-xl border border-white/50 bg-white/70 p-6 shadow-lg backdrop-blur-sm">
								<img
									alt="Empirium Blueprint"
									loading="lazy"
									src={uavSchematic}
									className="h-auto w-full rounded-lg object-cover shadow-inner"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
