import { validationError } from '@rvf/react-router'
import { data, type ActionFunctionArgs, type MetaFunction } from 'react-router'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { Cta } from './__components/cta'
import { Features } from './__components/features'
import { Hero } from './__components/hero'
import { validator } from './__components/join-waitlist'
// import { Testimonials } from './__components/testimonials'

const hubspotUrl = 'https://api.hubapi.com/crm/v3/objects/contacts'

export const meta: MetaFunction = () => {
	return [
		{ title: 'Home | Empirium' },
		{
			name: 'description',
			content:
				'A trusted B2B/B2G marketplace hosting everything from raw materials to finished goods, including manufacturing and financial services, serving allied defense, aerospace, transportation, and energy sectors.',
		},
	]
}

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()
	await checkHoneypot(formData)
	const result = await validator.validate(formData)

	if (result.error) {
		return validationError(result.error)
	}

	const {
		companyName,
		companyWebsite,
		contactFirstName,
		contactLastName,
		contactTitle,
		contactEmail,
		contactPhone,
		customerType,
		waitlistOrDemo,
	} = result.data

	try {
		const res = await fetch(hubspotUrl, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				associations: [
					{
						types: [
							{
								// Uses the hubspot defined assoications
								// https://developers.hubspot.com/docs/api/crm/associations#association-type-id-values
								associationCategory: 'HUBSPOT_DEFINED',
								associationTypeId: 449,
							},
						],
						to: {
							// This is the contact object ID
							// Reporting & Data > Data Model
							id: '1',
						},
					},
				],
				properties: {
					company: companyName,
					website: companyWebsite.includes('https')
						? companyWebsite
						: 'https://' + companyWebsite,
					customer_type: customerType,
					firstname: contactFirstName,
					lastname: contactLastName,
					jobtitle: contactTitle,
					email: contactEmail,
					phone: contactPhone,
					waitlist_or_demo: waitlistOrDemo,
				},
			}),
		})
		return res.json()
	} catch (e: any) {
		console.log('An error occurred when submitting to hubspot', e)
		throw data(e)
	}
}

export default function Marketing() {
	return (
		<>
			<Hero />
			<Features />
			{/* TODO: Add testimonials once they're available */}
			{/* <Testimonials /> */}
			<Cta />
		</>
	)
}
