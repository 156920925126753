import {
	ShoppingBag,
	Search,
	Shield,
	FileQuestion,
	Earth,
	UserRoundCheck,
} from 'lucide-react'
import { useEffect, useRef } from 'react'
import { Card, CardContent, CardTitle } from '#app/components/ui/card.tsx'

interface FeatureProps {
	icon: React.ReactNode
	title: string
	description: string
	delay: string
}

const Feature: React.FC<FeatureProps> = ({
	icon,
	title,
	description,
	delay,
}) => (
	<Card className={`glassmorphism p-6 opacity-0 animate-${delay}`}>
		<div className="mb-4 inline-flex h-12 w-12 items-center justify-center rounded-xl bg-empirium-teal/10 text-empirium-teal">
			{icon}
		</div>
		<CardTitle className="mb-2 text-lg font-semibold">{title}</CardTitle>
		<CardContent className="p-0 text-gray-600">{description}</CardContent>
	</Card>
)

export const Features = () => {
	const sectionRef = useRef<HTMLElement>(null)

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry?.isIntersecting) {
					const features = entry.target.querySelectorAll('.glassmorphism')
					features.forEach((feature, index) => {
						setTimeout(() => {
							feature.classList.remove('opacity-0')
							feature.classList.add('animate-fade-in')
						}, index * 150)
					})
				}
			},
			{ threshold: 0.1 },
		)

		if (sectionRef.current) {
			observer.observe(sectionRef.current)
		}
		const sectionReference = sectionRef.current

		return () => {
			if (sectionReference) {
				observer.unobserve(sectionReference)
			}
		}
	}, [])

	const features = [
		{
			icon: <ShoppingBag className="h-6 w-6" />,
			title: 'Increase Sales, Reduce Friction',
			description:
				'Promote your brand through digital storefronts designed to streamline sales and simplify buyer decisions.',
		},
		{
			icon: <Search className="h-6 w-6" />,
			title: 'Find Trusted Suppliers',
			description:
				'Locate, communicate, and transact with vetted and compliant suppliers all in one place.',
		},
		{
			icon: <Shield className="h-6 w-6" />,
			title: 'Security First',
			description:
				'We use a multi-layered security framework to keep the bad guys out of your supply chains.',
		},
		{
			icon: <Earth className="h-6 w-6" />,
			title: '24/7 Industrial Base',
			description:
				'Empirium is an always-on, compliant, and global industrial marketplace accessible at your fingertips.',
		},
		{
			icon: <UserRoundCheck className="h-6 w-6" />,
			title: 'Know Your Customer',
			description:
				'All buyers and sellers undergo identity verification checks using powerful KYC, KYB, and AML tools.',
		},
		{
			icon: <FileQuestion className="h-6 w-6" />,
			title: 'Eliminate Guesswork',
			description:
				'We partner with quality experts to ensure producers meet standards and deliver as promised.',
		},
	]

	return (
		<section
			id="features"
			ref={sectionRef}
			className="py-16 md:py-24"
			style={{
				background: 'linear-gradient(135deg, #FFFFFF 0%, #F5F7FA 100%)',
			}}
		>
			<div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mb-16 text-center">
					<div className="mb-4 inline-flex items-center rounded-full bg-empirium-teal/10 px-3 py-1 text-sm font-medium text-empirium-teal">
						Why Choose Empirium?
					</div>
					<h2 className="mb-4 text-3xl font-bold text-empirium-dark md:text-4xl">
						End-to-End Trade Solutions
					</h2>
					<p className="mx-auto max-w-3xl text-lg text-gray-600">
						Use a complete suite of trade tools for payments, logistics,
						compliance, and communication.
					</p>
				</div>

				<div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
					{features.map((feature, index) => (
						<Feature
							key={index}
							icon={feature.icon}
							title={feature.title}
							description={feature.description}
							delay={`fade-in-delay-${Math.min(index + 1, 4)}`}
						/>
					))}
				</div>
			</div>
		</section>
	)
}
