import { useEffect, useRef } from 'react'
import { Card } from '#app/components/ui/card.tsx'
import { JoinWaitlist } from './join-waitlist'

export const Cta = () => {
	const sectionRef = useRef<HTMLElement>(null)
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry?.isIntersecting) {
					entry.target.classList.add('opacity-100')
				}
			},
			{
				threshold: 0.1,
			},
		)
		if (sectionRef.current) {
			observer.observe(sectionRef.current)
		}
		const sectionReference = sectionRef.current
		return () => {
			if (sectionReference) {
				observer.unobserve(sectionReference)
			}
		}
	}, [])
	return (
		<section
			id="contact"
			ref={sectionRef}
			className="py-16 opacity-0 transition-opacity duration-700 ease-in-out md:py-24"
			style={{
				background: 'linear-gradient(135deg, #1A2238 0%, #0D7377 100%)',
			}}
		>
			<Card className="container mx-auto max-w-7xl border-none bg-transparent px-4 sm:px-6 lg:px-8">
				<div className="relative overflow-hidden rounded-2xl border border-white/10 bg-gradient-to-br from-empirium-accent/20 to-empirium-teal/20 p-8 backdrop-blur-lg md:p-12 lg:p-16">
					<div className="absolute right-0 top-0 -mr-20 -mt-20 h-96 w-96 rounded-full bg-gradient-to-br from-empirium-teal/30 to-empirium-accent/30 blur-3xl"></div>

					<div className="max-w-3xl">
						<h2 className="mb-6 text-3xl text-white md:text-4xl">
							Ready to Transform Your Supply Chain?
						</h2>
						<p className="mb-8 text-lg text-white/80">
							Join thousands of businesses using Empirium to find trusted
							partners, streamline operations, and grow globally. Get started
							today and see the difference.
						</p>

						<div className="flex flex-col gap-4 sm:flex-row">
							<JoinWaitlist intent="join-waitlist" />
							<JoinWaitlist intent="request-demo" />
						</div>
					</div>
				</div>
			</Card>
		</section>
	)
}
